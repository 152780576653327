<template>
  <div class="feedBackPage">
    <!-- <headerComponent /> -->

    <img :src="require('@/assets/bgc3.jpg')" alt="" class="banner" />

    <div class="Feedback_inpBox">
      <div class="title">给我们留言建议吧</div>
      <div class="inp_box">
        <div class="l_inp">
          <div class="txt">称呼:</div>
          <el-input placeholder="输入姓名" v-model="name" clearable> </el-input>
        </div>
        <div class="r_inp">
          <div class="txt1">联系方式(邮箱):</div>
          <el-input placeholder="输入联系方式" v-model="phone" clearable>
          </el-input>
        </div>
      </div>

      <div class="Feedback_inp">
        <div class="txt">描述:</div>

        <el-input
          type="textarea"
          :rows="7"
          placeholder="非常感谢您的真知灼见，我们将迅速落实并反馈。"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div class="Feedback_inp1">
        <div class="left">
          <div class="txt">上传截图:</div>
          <FeedbackUpload ref="FeedbackUpload1" />
        </div>
        <div class="right">
          <div class="btn" @click="addFeedback">提交反馈</div>
        </div>
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import { addContactUs } from "api/introduce";
import FeedbackUpload from "components/disk/FeedbackUpload.vue";
import headerComponent from "./headerComponent";
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "feedBackPage",
  //import引入的组件需要注入到对象中才能使用
  components: {
    // headerComponent,
    commonFooter,
    FeedbackUpload,
  },
  data() {
    //这里存放数据
    return {
      name: "",
      phone: "",
      textarea: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async addFeedback() {
      // let reg = /^1[0-9]{10}$/;
      // if (!this.phone) {
      //   this.$message({
      //     type: "error",
      //     message: "请输入手机号!"
      //   });
      //   return;
      // }
      // if (!reg.test(this.phone)) {
      //   this.$message({
      //     type: "error",
      //     message: "请输入正确的手机号!"
      //   });
      //   return;
      // }

      if (this.textarea == "") {
        this.$message({
          type: "error",
          message: "请输入反馈内容!",
        });
        return;
      }
      const data = {
        name: this.name,
        mobile: this.phone,
        platform: 3,
        content: this.textarea,
        type: 1,
        pictures: this.$store.state.imgList,
      };
      const res = await addContactUs(data);
      if (res.code != 200) return;

      this.$message({
        type: "success",
        message: "谢谢您的反馈,我们会尽快处理的!",
      });
      this.name = "";
      this.phone = "";
      this.textarea = "";
      this.$refs.FeedbackUpload1.img = [];
      this.$refs.FeedbackUpload1.arr = [];
      this.$store.commit("GetimgList", []);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.feedBackPage {
  position: absolute;
  background: #f5f5f2;
  top: 0px;
  left: 0;
  width: 100%;

  .banner {
    width: 100%;
    height: 420px;
  }

  .Feedback_inpBox {
    width: 1450px;
    height: 520px;
    box-sizing: border-box;
    padding: 50px 150px;
    background: #ffffff;
    box-shadow: 0px 9px 24px 0px rgba(129, 135, 144, 0.13);
    border-radius: 5px;
    margin: 0 auto;
    transform: translateY(-40px);
    .title {
      font-size: 30px;
      text-align: center;
      font-weight: bold;

      span {
        font-size: 20px;
        color: #999999;
      }
    }

    .inp_box {
      margin: 40px 0;
      align-items: center;
      display: flex;
      justify-content: space-between;
      .l_inp,
      .r_inp {
        display: flex;
        align-items: center;
        .txt {
          width: 50px;
          margin-right: 15px;
        }
        .txt1 {
          width: 130px;

          margin-left: 10px;
        }

        .el-input {
          width: 396px;
        }
      }
    }

    .Feedback_inp {
      display: flex;

      .txt {
        width: 50px;
        margin-right: 20px;
      }

      .el-textarea {
        ::v-deep .el-textarea__inner {
          resize: none;
        }
      }
    }
    .Feedback_inp1 {
      margin-top: 40px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
      }

      .txt {
        margin-right: 20px;
      }
    }
    .btn {
      cursor: pointer;
      float: right;
      width: 240px;
      height: 55px;
      display: flex;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      background-image: linear-gradient(to right, #ff6900, #ee9a3c);
    }
  }
}
</style>
